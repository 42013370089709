import { useState, useEffect } from 'react'

import { initializeApp } from "firebase/app"
import { getDatabase, ref, onValue } from "firebase/database"

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts"

initializeApp({
    apiKey: "AIzaSyBY9Rb4f4O-s4Um1UzotcXQrGUQEIzJ5uM",
    authDomain: "cbf2021-tickets.firebaseapp.com",
    databaseURL: "https://cbf2021-tickets-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "cbf2021-tickets",
    storageBucket: "cbf2021-tickets.appspot.com",
    messagingSenderId: "501547550823",
    appId: "1:501547550823:web:c8bfb50f08b187959a2cc2",
})

function App() {
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [entryOnly, setEntryOnly] = useState(0)
    const [beerEntry, setBeerEntry] = useState(0)

    useEffect(() => {
        const database = getDatabase()
        const doc = ref(database, "sales")
        onValue(doc, (snap) => {
            const salesData = snap.val()
            setData(
                Object.entries(salesData).map(([name, data]) => ({
                    name,
                    "Beer entry": data.beerEntry,
                    "Entry only": data.entryOnly,
                }))
            )
            setTotal(Object.values(salesData).reduce((pre, cur) => pre + cur.entryOnly + cur.beerEntry, 0))
            setEntryOnly(Object.values(salesData).reduce((pre, cur) => pre + cur.entryOnly, 0))
            setBeerEntry(Object.values(salesData).reduce((pre, cur) => pre + cur.beerEntry, 0))
        })
    }, [])

    return (
        <div>
            {data.length > 0  && <>
                <div style={{maxWidth: "980px"}}>
                    <ResponsiveContainer height={400}>
                        <BarChart
                            margin={{ top: 0, right: 0, left: -30, bottom: 0 }}
                            data={data}
                            barSize={20}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="Entry only" stackId="a" fill="#8884d8" />
                            <Bar dataKey="Beer entry" stackId="a" fill="#82ca9d" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
                <p>Total tickets sold: {total}</p>
                <p>Entry only: {entryOnly}</p>
                <p>Beer entry: {beerEntry}</p>
            </>}
        </div>
    )
}

export default App
